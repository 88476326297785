import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "set-language-of-chat-and-tab"
    }}>{`Set language of chat and tab`}</h1>
    <p>{`You can set the locale at runtime by giving the script `}<inlineCode parentName="p">{`locale`}</inlineCode>{` property at runtime. Supported locales are `}<em parentName="p">{`eng`}</em>{`, `}<em parentName="p">{`fin`}</em>{`, `}<em parentName="p">{`swe`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    locale: 'eng'
  });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      